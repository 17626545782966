import React from 'react';
import './css/Style.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

import logo from './img/logo.png';
import {useState,useEffect} from 'react';

import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'



function Header() {
    const [expanded, setExpanded] = useState(false);
  return (
    
    <header className="site-header">
        <div className="top-header-bar" style={{display:"none"}}>
            <div className="container">
                <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
                    <div className="col-12 col-lg-8 d-none d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                        <div className="header-bar-email">
                        <i class="fa fa-envelope"></i> <a href="#">contact@ourcharity.com</a>
                        </div>

                        <div className="header-bar-text">
                            <p><i class="fa fa-phone"></i> <span>+24 3772 120 091 / +56452 4567</span></p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center">
                        <div className="donate-btn">
                            <a href="#">Donate Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
            <div className="col-12 ">

        <Navbar expanded={expanded} collapseOnSelect expand="lg" bg="white" variant="white">
                <Navbar.Brand>
                    <Link to="/"><img className="d-block" src={logo}  alt="logo"></img></Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>
                        <Link to="/" onClick={() => setExpanded(false)} className="nav-link">Home</Link>
                        <Link to="/about"onClick={() => setExpanded(false)}  className="nav-link">About Us</Link>
                        <Link to="/about-pallana" onClick={() => setExpanded(false)}  className="nav-link" >About Pallana</Link>
                        <Link to="/gallery" onClick={() => setExpanded(false)} className="nav-link" >Gallery</Link>
                        <Link to="/contact" onClick={() => setExpanded(false)} className="nav-link">Contact</Link>
                    </Nav>
                </Navbar.Collapse>
        </Navbar>

            </div>
            </div>
        </div>
        
        <div className="nav-bar">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                        

                        {/* <nav className="site-navigation d-flex justify-content-end align-items-center">
                            <ul className="d-flex flex-column flex-lg-row justify-content-lg-end align-content-center">
                                <li className="current-menu-item"><Link to="/">Home</Link></li>
                                <li><Link to="/about">About us</Link></li>
                                <li><Link to="/about-pallana">About Pallana</Link></li>
                                <li><Link to="/gallery">Gallery</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </nav> 

                        

                        <div className="hamburger-menu d-lg-none">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </header>
   
  );
}

export default Header;
