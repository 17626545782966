import React from 'react';
import './css/Style.css';

import welcome_bg from './img/about-bg.jpg';
import welcome from './img/welcome.jpg';


function Home_welcome() {
  return (
    
    <div className="home-page-welcome" style={{ background: "url("+welcome_bg+") " }}>
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6 order-2 order-lg-1">
                    <div className="welcome-content">
                        <header className="entry-header">
                            <h2 className="entry-title">ദേശാടന കാഴ്ചകൾ Desadana Kazhchakal</h2>
                        </header>

                        <div className="entry-content mt-5">
                            <p>ലോകത്തെമ്പാടും ഉള്ള വൈവിധ്യം നിറഞ്ഞ കാഴ്ച കള്‍ മലയാളിക്കു തനി നാടന്‍ ആവിഷ്കാര രീതി യിലുടെ പരിചയപ്പെടുത്താനുള്ള ഒരു ചാനല്‍ ആണ്.</p>
                            <p>സഞ്ചാര പ്രേമിയായ ആബീബെ (www.abeebe.com) ഡയറക്ടര്‍ മനോഹരന്‍റെ ഓര്‍മ്മക്കായി സമര്‍പ്പിക്കുന്നു. ഈ ചാനല്‍ കൂടി എന്തെങ്കിലും വരുമാനം ഉണ്ടാകുവാനങ്കില്‍ നൂറു ശതമാനം ചാരിറ്റി (manoharanmemmorial.org) വേണ്ടി ഉപയോഗിക്കും..</p>
                        </div>

                        <div className="entry-footer mt-5">
                            <a href="https://www.youtube.com/channel/UCswRFxNBMbzTiGklB4efRRw" target="_blank" className="btn gradient-bg mr-2">Go to channel</a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 mt-4 order-1 order-lg-2">
                    {/* <img src={welcome} alt="welcome"></img> */}
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/zlwvM8OLujs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
  </div>

  );
}

export default Home_welcome;
