import React from 'react';
import './css/Style.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

import foot_logo from './img/foot-logo.png'

function Footer() {
  return (

        <footer className="site-footer">
        <div className="footer-widgets">
            <div className="container">
                <div className="row">

                <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
                        <h2>Useful Links</h2>

                        <ul>
                            <li className="current-menu-item"><Link to="/">Home</Link></li>
                            <li><Link to="/about">About us</Link></li>
                            <li><Link to="/about-pallana">About Pallana</Link></li>
                            <li><Link to="/gallery">Gallery</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3">
                        <h2>Our Channel</h2>
                        <div className="foot-about">
                        <iframe width="100%" height="auto" src="https://www.youtube.com/embed/4nX4JM5Ix40" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <ul className="d-flex flex-wrap align-items-center">
                                <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                {/* <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                                <li><a href="#"><i className="fa fa-behance"></i></a></li>
                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li> */}
                            </ul>
                        </div>
                </div>

                    <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
                        <div className="foot-latest-news">
                            <h2>Our Partner</h2>

                            <ul class="footr-partner">
                                <li>
                                    <img src="https://abeebe.com/wp-content/uploads/2017/09/abeebe-logo-dark.svg"  />
                                </li>

                                <li>
                                   <img src="https://webcloudllc.com/wp-content/uploads/2019/04/webcloud.png" width="70%"/>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
                        <div className="foot-contact">
                            <h2>Contact</h2>

                            <ul>
                                <li><i className="fa fa-phone"></i><span>+91 9846126668</span></li>
                                <li><i className="fa fa-envelope"></i><span>info@manoharanmemorial.org</span></li>
                                <li><i className="fa fa-map-marker"></i><span>Ayodhya Building, Pallana.P.O, Kerala, India</span></li>
                            </ul>
                        </div>

                        {/* <div className="subscribe-form">
                            <form className="d-flex flex-wrap align-items-center">
                                <input type="email" placeholder="Your email"></input>
                                <input type="submit" value="send"></input>
                            </form>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-bar">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="m-0">2021 powered and designed by <a href="https://abeebe.com">Abeebe</a> . All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
                         
    </footer>

  );
}

export default Footer;
