import React from 'react';
import './css/Style.css';

import about_bg from './img/about-bg.jpg';


function Inner_banner({title}) {
  return (
    
    // <div className="">
    //   <img src={hero} style={{ height: "500px",width: "100%",objectFit: "cover",objectPosition: "center" }}/>
    // </div>

    <div className="page-header"  style={{ background:"url("+about_bg+")",height: "200px",width: "100%",objectFit: "cover",objectPosition: "center" }} >
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <h1>{title}</h1>
              </div>
          </div>
      </div>
    </div>

  );
}

export default Inner_banner;
