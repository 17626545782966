import React from 'react';
import './css/Style.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  //import welcome from './img/welcome.jpg';

  import manoharan from './img/manoharan.png';


function About() {
  return (

    
    <div className="welcome-wrap">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6 order-2 order-lg-1">
                    <div className="welcome-content">

                        <div className="entry-content mt-5">
                        <header className="entry-header">
                            <h2 className="entry-title">About Manoharan</h2>
                        </header>
                        <p>Mr. Manoharan (10th February 1948 to 2nd June 2020) born in a village called pallana 
                            (Alappuzha District, Kerala) which is situated in between Arabian Sea and Pallana river. 
                            After working for around two decades for the Indian Air Force, he became a businessman 
                            and one of the founding directors of Abeebe IT. He was committed, honest, hardworking, 
                            always fought for justice and loves to travel. His social commitment and humanitarian work 
                            irrespective of caste, religion, politics and social status is ever remembered.</p>
                        
                        <p>Manoharan was the eldest among five children of Konnatharayil G. Kesavan and Madhavi. 
                        He had his primary education from KAMUPS, Pallana and SNMHS,Purakkad. He was a role model for 
                        many youngsters in this village. Manoharan was a staunch atheist throughout his life, and will 
                        always be remembered for his simplicity and kindness.He was laid to rest at his home Pallana with
                         honor. He is survived by his wife and his two children.</p>

                        </div>

                        {/* <div className="entry-footer mt-5">
                            <a href="#" className="btn gradient-bg mr-2">Read More</a>
                        </div> */}
                    </div>
                </div>

                <div className="col-12 col-lg-6 order-1 order-lg-2">
                    <img src={manoharan}  alt="Manoharan"></img>
                </div>
            </div>
        </div>
    </div>

  );
}

export default About;
