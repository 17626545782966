import React from 'react';
import './css/Style.css';

import hero from './img/hero.jpg';


function Slider() {
  return (
    
    <div className="Home-Slider">
      <img src={hero} />
    </div>

  );
}

export default Slider;
