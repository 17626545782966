import React from 'react'
import campus from './img/KAMUPS_pallana.jpeg';

function AboutPallana() {
    return (
        <div className="welcome-wrap">
        <div className="container">
        
        
            
            
            <div className="row">
                
                <div className="col-md-6">  
                   <img src={campus}  alt="campus"></img>
                </div>     

                <div className="col-md-6 content-box">

                <header className="entry-header">
                    <h2 className="entry-title">About Pallana</h2>
                </header>
                        
                    <div className="welcome-content">

                        <div className="entry-content mt-5">
                        <p>Pallana is always remembered with the great poet Mahakavi Kemaranasan. The eternal rest place 
                            (memorial tromp) of Kumaranasan is situated in Kumarakodi, Pallana.This village at the shore of 
                            Arabian sea and rounded by a river called “pallana river”.Pallana is proud of its tradition, culture 
                            and uniqueness. Main revenue of this village is from agriculture and fishing.</p>
                        
                        <p>In memory of Asan there are two levels of schools which are KAMUPS, MKMHSS and a library 
                            dedicated to Asan poetries. Sree porkali devi temple is one of the religious icons in this 
                            area. This place is famous in Alappuzha district in the name of tourism.</p>
                        
                        <p>People in this village are well educated and have proven excellence in arts, science, Information
                             technology and other competition exams.</p>

                        </div>
                    </div>
                </div>

               
            </div>
        </div>
    </div>
    )
}

export default AboutPallana
