import React from 'react'

function Contact() {
    return (
        <div className="welcome-wrap">
        <div className="container">
        
        
            
            
            <div className="row">
                
                <div className="col-md-6 contact-frame-wrap">  
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62997.534046290384!2d76.38804300000001!3d9.302589!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5d8e50cf34337126!2sAbeebe%20IT%20and%20Digital%20Solutions%20Private%20Ltd.!5e0!3m2!1sen!2sus!4v1622479330384!5m2!1sen!2sus" 
                 allowfullscreen="" className="contact-iframe"></iframe>
                </div>     

                <div className="col-md-6 content-box">

                <header className="entry-header">
                    <h2 className="entry-title">Contact Us</h2>
                </header>
                        
                    <div className="welcome-content">

                        <div className="entry-content mt-5">
                        <ul>
                            <li>Ayodhya Building,</li>
                            <li>Pallana.P.O,</li>
                            <li>Kerala, India</li>
                            <li>Mob : 9846126668</li>
                            <li>Mail: info@abeebe.com</li>
                        </ul>

                        </div>
                    </div>
                </div>

               
            </div>
        </div>
    </div>
    )
}

export default Contact
