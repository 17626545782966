import React from 'react';
import manoharan from './img/manoharan.png';

function Home_about(){
    return(
        <div className="container home_ahout">
            <div className="row">
            <div className="col-md-8 home_abt_content">
                    <div className="">
                     <h2>About Manoharan</h2>
                      <p>Mr. Manoharan (10th February 1948 to 2nd June 2020) born in a village called pallana (Alappuzha 
                          District, Kerala) which is situated in between Arabian Sea and Pallana river. After working for 
                          around two decades for the Indian Air Force, he became a businessman and one of the founding 
                          directors of Abeebe IT. He was committed, honest, hardworking, always fought for justice and loves 
                          to travel. His social commitment and humanitarian work irrespective of caste, religion, politics and
                           social status is ever remembered.</p>
                        
                      <p>Manoharan was the eldest among five children of Konnatharayil G. Kesavan and Madhavi. He had his
                           primary education from KAMUPS, Pallana and SNMHS,Purakkad. He was a role model for many youngsters 
                           in this village.</p>
                      <p>Manoharan was a staunch atheist throughout his life, and will always be remembered for his 
                          simplicity and kindness.He was laid to rest at his home Pallana with honor. He is survived by 
                          his wife and his two children.</p>
                    </div>
                </div>

                <div className="col-md-4 home_abt_wrap">
                     <div className="home_abt_img">
                       <img src={manoharan} className="img-fluid"/> 
                     </div>
                </div>
                
            </div>
        </div>
    );
}

export default Home_about;