import React from 'react';
import './css/Style.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  import welcome from './img/welcome.jpg';


import gal6 from './img/hg1.jpeg';
import gal4 from './img/hg2.jpeg';
import gal5 from './img/hg3.jpeg';
import gal2 from './img/hg4.jpg';
import gal3 from './img/hg5.jpg';
import gal1 from './img/hg6.jpg';

  

function Gallery() {
  return (

    
    <div className="galery-wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-4 gal">
                  <img src={gal1} />
                </div>  
                <div className="col-md-4 gal">
                  <img src={gal2} />
                </div> 
                <div className="col-md-4 gal">
                  <img src={gal3} />
                </div> 
                <div className="col-md-4 gal">
                  <img src={gal4} />
                </div> 
                <div className="col-md-4 gal">
                  <img src={gal5} />
                </div> 
                <div className="col-md-4 gal">
                  <img src={gal6} />
                </div> 
            </div>
        </div>
    </div>

  );
}

export default Gallery;
