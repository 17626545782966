import React from 'react';

import hg1 from './img/hg1.jpeg';
import hg2 from './img/hg2.jpeg';
import hg3 from './img/hg3.jpeg';
import hg4 from './img/hg4.jpg';
import hg5 from './img/hg5.jpg';
import hg6 from './img/hg6.jpg';

import {useState,useEffect} from 'react';

function Home_events() {

  const [pos, setCount] = useState(1);

    function makeActive(p){
        setCount(p);      
    }

  return (

    <>
    <div className="container home_gallery">
        <header className="entry-header">
            <h2 className="entry-title text-center">Memories</h2>
        </header>
    </div>

    <div className="container flxgalery" style={{display:"flex"}}>

    {/* <div className={pos==2 ? 'panel active':'panel'}></div> */}

    <div className={pos==1 ? 'panel active':'panel'}
    onClick={ ()=> makeActive(1)}
    style={{ background: "url("+hg1+") " }} >
        <h3>Pallana Campus</h3>
    </div>

    <div  className={pos==2 ? 'panel active':'panel'}
         onClick={ ()=> makeActive(2)}
    style={{ background: "url("+hg3+") " }}>
        <h3>Pallana Temple</h3>
    </div>

    <div className={pos==3 ? 'panel active':'panel'} 
    onClick={ ()=> makeActive(3)}
    style={{ background: "url("+hg2+") " }}>
        <h3>Pallana School</h3>
    </div>

    <div className={pos==4 ? 'panel active':'panel'}
    onClick={ ()=> makeActive(4)}
    style={{ background: "url("+hg4+") " }}>
        <h3>City on Nature</h3>
    </div>

    <div className={pos==5 ? 'panel active':'panel'}
    onClick={ ()=> makeActive(5)}
        style={{ background: "url("+hg5+") " }}>
        <h3>Mountains - Clouds</h3>
    </div>
</div>

</>

/* <div className="home-page-events">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="upcoming-events">
                        <div className="section-heading">
                            <h2 className="entry-title">Upcoming Events</h2>
                        </div>

                        <div className="event-wrap d-flex flex-wrap justify-content-between">
                            <figure className="m-0">
                                <img src={event_1} alt=""></img>
                            </figure>

                            <div className="event-content-wrap">
                                <header className="entry-header d-flex flex-wrap align-items-center">
                                    <h3 className="entry-title w-100 m-0"><a href="#">Fundraiser for Kids</a></h3>

                                    <div className="posted-date">
                                        <a href="#">Aug 25, 2018 </a>
                                    </div>
                                    <div className="cats-links">
                                        <a href="#">Ball Room New York</a>
                                    </div>
                                </header>

                                <div className="entry-content">
                                    <p className="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempus vestib ulum mauris.</p>
                                </div>
                                <div className="entry-footer">
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div className="event-wrap d-flex flex-wrap justify-content-between">
                            <figure className="m-0">
                                <img src={event_2} alt=""></img>
                            </figure>

                            <div className="event-content-wrap">
                                <header className="entry-header d-flex flex-wrap align-items-center">
                                    <h3 className="entry-title w-100 m-0"><a href="#">Bring water to the childrens</a></h3>

                                    <div className="posted-date">
                                        <a href="#">Aug 25, 2018 </a>
                                    </div>

                                    <div className="cats-links">
                                        <a href="#">Ball Room New York</a>
                                    </div>
                                </header>

                                <div className="entry-content">
                                    <p className="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempus vestib ulum mauris.</p>
                                </div>

                                <div className="entry-footer">
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div className="event-wrap d-flex flex-wrap justify-content-between">
                            <figure className="m-0">
                                <img src={event_3} alt=""></img>
                            </figure>

                            <div className="event-content-wrap">
                                <header className="entry-header d-flex flex-wrap align-items-center">
                                    <h3 className="entry-title w-100 m-0"><a href="#">Bring water to the childrens</a></h3>

                                    <div className="posted-date">
                                        <a href="#">Aug 25, 2018 </a>
                                    </div>

                                    <div className="cats-links">
                                        <a href="#">Ball Room New York</a>
                                    </div>
                                </header>

                                <div className="entry-content">
                                    <p className="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempus vestib ulum mauris.</p>
                                </div>

                                <div className="entry-footer">
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="featured-cause">
                        <div className="section-heading">
                            <h2 className="entry-title">Featured Cause</h2>
                        </div>

                        <div className="cause-wrap d-flex flex-wrap justify-content-between">
                            <figure className="m-0">
                                <img src={featured_causes} alt=""></img>
                            </figure>

                            <div className="cause-content-wrap">
                                <header className="entry-header d-flex flex-wrap align-items-center">
                                    <h3 className="entry-title w-100 m-0"><a href="#">Fundraiser for Kids</a></h3>

                                    <div className="posted-date">
                                        <a href="#">Aug 25, 2018 </a>
                                    </div>

                                    <div className="cats-links">
                                        <a href="#">Ball Room New York</a>
                                    </div>
                                </header>
                                <div className="entry-content">
                                    <p className="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempus vestib ulum mauris. Lorem ipsum dolor sit amet, consectetur.</p>
                                </div>

                                <div className="entry-footer mt-5">
                                    <a href="#" className="btn gradient-bg mr-2">Donate Now</a>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>     */

        
  );
  
}




export default Home_events;
