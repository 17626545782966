import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Style.css';

import Header from './Header.js'
import Slider from './Slider.js'
import Home_icon_box from './Home_icon_box.js';

import Footer from './Footer.js'

import Home_about from './Home_about';
import Home_welcome from './Home_welcome';
import Home_events from './Home_events';
import Home_gallery from './Home_gallery';


import About from './About';
import AboutPallana from './AboutPallana';
import Inner_banner from './Inner_banner';
import Gallery from './Gallery';
import Contact from './Contact';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


function App() {
  return (
    <>
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
    
    <Router>
    
    <Header/>

        <Switch>
        <Route path="/about">
            <div className="single-page about-page">
            <Inner_banner title="About" />
            <About />
            </div>
        </Route>

        <Route path="/about-pallana">
            <div className="single-page about-pallana">
            <Inner_banner title="About Pallana" />
            <AboutPallana />
            </div>
        </Route>

        <Route path="/gallery">
            <div className="single-page about-page">
            <Inner_banner title="Gallery" />
            </div>
            <Gallery/>
        </Route>

        <Route path="/news">
            <div className="single-page about-page">
            <Inner_banner title="News" />
            </div>
            <Gallery/>
        </Route>

        <Route path="/contact">
            <div className="single-page contact">
            <Inner_banner title="Contact" />
            </div>
            <Contact/>
        </Route>

        <Route path="/"  exact >

        <Slider/>  
        {/* <Home_icon_box/> */}
        <Home_about/>
        <Home_welcome />
        
        {/* <Home_gallery />*/}
       <Home_events /> 
        
        </Route>
        </Switch>


    
    <Footer />

    </Router>

 
</>

  );
}

export default App;
